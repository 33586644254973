*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background: black;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('assets/fonts/BebasNeue-Regular.woff2') format('woff2'),
  url('assets/fonts/BebasNeue-Regular.woff') format('woff');
  font-style: normal;
  font-display: swap;
}


body {
  font-family: 'Bebas Neue', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

/*
 Menu animation
 */
.menu-enter {
  opacity: 0;
  transform: scale(0.9);
}
.menu-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.menu-exit {
  opacity: 1;
}
.menu-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
